//== Colors


// Backgrounds
$background--dark: #000;
$background--light: #fff;

// Brand Colors
$brand--black: #161616;
$brand--secondary-black: #464646;

$brand--red: #A80F25;
$brand--secondary-red: #660916;

$brand--yellow: #FFC43F;
$brand--secondary-yellow: #AD842A;

$brand--blue: #0349A5;
$brand--secondary-blue: #003479;

$brand--green: #709E37;
$brand--secondary-green: #567A2A;

$brand--white: #FFFFFF;
$brand--secondary-white: #9F9F9F;

// Font Colors
$text--light: #FFFFFF;
$text--dark: #161616;
