@import '../../../styles/theme';

.sectionContent {
  text-align: center;
  z-index: 2000;
  margin: 24px auto 0px auto;
  padding: 0px;
}

.checkpointContent {
  margin: 0px auto;
  padding: 0px;
  padding-top: 24px;
}

.checkpointContent > h2 {
  margin-top: 0px;
  padding-top: 0px;
}

.checkpointContent > p {
  font-size: 18px;
  line-height: 1.5em;
}

.navLinks {
  position: fixed;
  width: 100%;
  margin: 0px auto;
  top: 16px;
  left: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

@media (max-width: 700px) {
  .navLinks {
    bottom: 0px;
    padding-top: 16px;
    top: auto;
    font-size: 12px !important;
  }
  .checkpointContent > h2 {
    margin-top: -28px;
    padding-top: 0px;
    font-size: 16px;
  }}

@media (max-width: 1400px) {
  .checkpointContent > p {
    font-size: 18px;
  }
  .checkpointContent > h2 {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .checkpointContent > p {
    font-size: 16px;
  }
  .checkpointContent > h2 {
    font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .checkpointContent > p {
    font-size: 15px;
  }
  .checkpointContent > h2 {
    font-size: 16px;
  }
}


@media (max-width: 700px) {
  .checkpointContent > p {
    font-size: 14px;
  }
  .checkpointContent > h2 {
    font-size: 14px;
  }
}

