@import '../../../../styles/theme';

$triangle--size: 8px;

.checkpointLinkContent {
  margin: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  z-index: 100;
}

.checkpointLinkContent > p {
  font-size: 16px;
  font-weight: bold;
  margin: 0px 12px;
}

.rightArrow {
  width: 0; 
  height: 0; 
  border-top: $triangle--size solid transparent;
  border-bottom: $triangle--size solid transparent;
  border-left: $triangle--size solid black;
}

.leftArrow {
  width: 0; 
  height: 0; 
  border-top: $triangle--size solid transparent;
  border-bottom: $triangle--size solid transparent; 
  border-right: $triangle--size solid black; 
}

@media (max-width: 700px) {
  $small-triangle--size: 6px;

  .checkpointLinkContent {
    margin: 0px 12px;
    padding: 22px 0px 4px 0px;
  }

  .checkpointLinkContent > p {
    font-size: 12px;
  }

  .rightArrow {
    border-top: $small-triangle--size solid transparent;
    border-bottom: $small-triangle--size solid transparent;
    border-left: $small-triangle--size solid black;
  }

  .leftArrow {
    border-top: $small-triangle--size solid transparent;
    border-bottom: $small-triangle--size solid transparent; 
    border-right: $small-triangle--size solid black; 
  }
}