@import '../../../../styles/theme';

.active {
  background-color: $brand--blue;
}

.viewed {
  background-color: lightgrey;
}

.unviewed {
  background-color: white;
}