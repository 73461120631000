.logo {
  margin: 36px auto 24px auto;
  background-image: url('../../../assets/logo.svg');
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 54;
  height: 54px;
}

.valuesTitle {
  text-align: center;
  font-weight: normal;
  font-size: 36px;
  line-height: 54px;
  margin: 36px 100px;
}

.bold {
  font-weight: bold !important;
}

.coreValuesHeader {
  margin: 48px auto 12px auto;
  padding: 6px;
  border-radius: 48px;
  background-color: #ddd;
  width: 300px;
  text-align: center;
}

.number {
  background-color: #ddd;
  padding: 0px;
  text-align: center;
  font-weight: bold;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  flex-shrink: 0;

}

.valuesList {
  width: 60%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 0px;
}

.valueText {
  font-size: 28px;
  line-height: 42px;
  margin: 0px 24px;
  padding: 0px;
}

@media (max-width: 700px) {
  .valuesTitle {
    font-size: 28px;
    line-height: 42px;
    margin: 36px 12px;
  }
  
  .valuesList {
    width: 90%;
  }
  
  .valueText {
    font-size: 18px;
    line-height: 27px;
  }
}