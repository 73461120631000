html {
  font-family: 'IBM Plex Sans', sans-serif;
}

h1 > .title {
  font-weight: bold;
  font-size: 56px;
}

h1 {
  font-weight: bold;
  font-size: 28px;
}

h2 {
  font-weight: bold;
  font-size: 22px;
}

h3 {
  font-weight: bold;
  font-size: 18px;
}

p {
  font-size: 18px;
}

.caption {
  font-size: 14px !important;
  font-weight: lighter;
  text-transform: uppercase;
}

.light {
  color: $text--light !important;
}

.dark {
  color: $text--dark !important;
}


@media (max-width: 600px) {
  #ballotDiv ul, div {
    min-width: 10px !important;
    padding: 3px 3px 3px 3px !important;
  }

  #ballotDiv h3 {
    font-size: 10pt !important;
  }

  #ballotDiv #voteBox {
    min-width: 12px !important;
  }

  #ballotDiv #spacer {
    min-width: 10px !important;
  }

  #ballotDiv li, td {
    padding: 5px 5px 5px 5px !important;
    font-size: 10pt;
  }
}
