@import '../../../../styles/theme';

.indicatorContent {
  position: fixed;
  left: 0px;
  top: 0px;
  margin: 0px;
  padding: 0px;
  height: 18px;
  width: 100%;
  cursor: pointer;
  z-index: 1023;
}

@media (max-width: 700px) {
  .indicatorContent {
    height: 6px;
  }
}