@import '../../styles/theme';

.content {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20000;
}

.introBody {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: scroll;
}

.getStartedButton {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid black;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  border-radius: 24px;
  text-align: center;
  margin: 36px auto 48px auto;
  width: 300px;
}

@media (max-width: 7) {
  
}