@import '../../styles/theme';

.content {
  padding: 0px;
  margin: 0px;
}

.sidebar {
  background-color: $brand--blue;
}

.openButton {
  background-image: url('../../assets/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 44px;
  height: 44px;
  background-color: transparent;
  cursor: pointer;
  position: fixed;
  top: 17px;
  left: 12px;
}

.sectionContent {
  margin: 0px auto;
  padding-top: 24px;
}

.graphContent {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 0px;
}

.visualization {
  position: fixed;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.visualizationOverlay {
  position: fixed;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
  background-color: transparent;
  z-index: 2;
}

.showIntro {
  position: fixed;
  bottom: 12px;
  right: 12px;
  font-size: 17px;
  font-weight: bold;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 2px solid #003479;
  text-align: center;
}

.questionMark {
  line-height: 32px;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 700px) {
  .visualization {
    position: absolute;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
  }

  .graphContent {
    position: static;
  }

  .visualizationOverlay {
    position: absolute;
  }
}

@media (max-width: 1px) {
  .mainContent {
    display: flex;
    flex-direction: column;
  }
  .content {
    flex-grow: 1;
  }
  .visualization {
    position: absolute;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    flex-grow: 0;
  }

  .graphContent {
    position: static;
  }

  .visualizationOverlay {
    position: absolute;
  }
}

input, button, submit { border:none; } 